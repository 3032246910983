<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="帖子内容：" prop="content">
          <el-input v-model="form.content" placeholder="请输入帖子内容"></el-input>
        </el-form-item>
        <el-form-item label="发帖人：" prop="author_name">
          <el-input v-model="form.author_name" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item label="精华帖：" prop="recommend_status">
          <el-select v-model="form.recommend_status" placeholder="请选择">
            <el-option v-for="item in recommendOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="置顶状态：" prop="top_status">
          <el-select v-model="form.top_status" placeholder="请选择置顶状态">
            <el-option v-for="item in isTopOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <template v-if="!$route.params.topic_id">
          <el-form-item v-if="!isGov" label="所属话题：" prop="topic_id">
            <el-select v-model="form.topic_id" placeholder="请选择所属话题">
              <el-option v-for="item in topicOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-else label="所属话题：" prop="topic_name">
            <el-select v-model="form.topic_name" placeholder="请选择所属话题">
              <el-option v-for="item in topicOptions" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </template>

        <el-form-item v-if="!isGov" label="所属小组：" prop="group_id">
          <el-select v-model="form.group_id" placeholder="请选择所属小组">
            <el-option v-for="item in groupOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-else label="所属小组：" prop="group_name">
          <el-select v-model="form.group_name" placeholder="请选择所属小组">
            <el-option v-for="item in groupOptions" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="filter-item">
        <DatePeriodPicker label="发布时间：" start-prop="create_start_time"
          :start-time.sync="form.create_start_time" end-prop="create_end_time"
          :end-time.sync="form.create_end_time" />
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="置顶时间：" start-prop="top_start_time" :start-time.sync="form.top_start_time"
          end-prop="top_end_time" :end-time.sync="form.top_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { groupOptions } from "../../api/group/group-list";
import { topicOptions } from "../../api/topic-admin/list";
import { getGovGroups, getGovTopics } from "@/modules/gov/api/post-library";
export default {
  name: 'post-list-filter',
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
    isGov: Boolean,
  },
  data() {
    return {
      rules: {},
      groupOptionsloading: false,
      groupOptions: [],
      topicOptionsloading: false,
      topicOptions: [],
      recommendOptions: [
        { name: "全部", id: -1 },
        { name: "未加精华", id: 0 },
        { name: "已加精华", id: 1 },
      ],
      isTopOptions: [
        { name: "全部", id: -1 },
        { name: "已置顶", id: 1 },
        { name: "未置顶", id: 0 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 导出
    // onExport() {
    //   const postData = { ...this.form, page: this.page };
    //   return topicsExport(postData)
    //     .then()
    //     .catch(() => {});
    // },
  },
  created() {
    this.groupOptionsloading = true;
    this.topicOptionsloading = true;
    if (this.isGov) {
      getGovGroups().then(res => {
        this.groupOptions = [{ id: -1, name: "全部" }, ...res.data];
        this.groupOptionsloading = false;
      })
      getGovTopics().then(res => {
        this.topicOptions = [{ id: -1, name: "全部" }, ...res.data];
        this.topicOptionsloading = false;
      })
    } else {
      groupOptions()
        .then((res) => {
          this.groupOptions = [{ id: -1, name: "全部" }, ...res.data];
          this.groupOptionsloading = false;
        })
        .catch((err) => {
          this.groupOptionsloading = false;
        });
      topicOptions({ group_id: 0 })
        .then((res) => {
          this.topicOptions = [{ id: -1, name: "全部" }, ...res.data];
          this.topicOptionsloading = false;
        })
        .catch((err) => {
          this.topicOptionsloading = false;
        });
    }
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
